/* Navbar.css

/* Keyframes for sliding menu */
@keyframes slideIn {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
  
  /* Keyframes for hover effects */
  @keyframes hoverScale {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  }
  
  /* Transition styles
  .transitions {
    transition: all 0.3s ease-in-out;
  }
  
  .hover\:text-main:hover {
    color: var(--main-color);
  }
  
  .hover\:bg-subMain:hover {
    background-color: var(--subMain-color);
  }
  
  .menu-slide-in {
    animation: slideIn 0.3s ease-in-out;
  }
  
  .menu-slide-out {
    animation: slideOut 0.3s ease-in-out;
  }
  
  .scale-hover:hover {
    animation: hoverScale 0.3s ease-in-out forwards;
  } */
   
  /* Cart badge animation */
  .cart-badge {
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  