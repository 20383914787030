/* Custom styles for the spinner */
.spinner-border {
    border-color: #f3f3f3;
    border-top-color: #3498db;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  