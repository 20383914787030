/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    width: 400px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #888;
  }
  
  .modal-body {
    margin-bottom: 20px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .btn {
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }
  
  .cancel-btn {
    background-color: #e0e0e0;
    color: #333;
    margin-right: 10px;
  }
  
  .confirm-btn {
    background-color: #4caf50;
    color: white;
  }
  