/* DashboardUserSignInTable.css */

.container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  
  .card p {
    margin-bottom: 0.5rem;
  }
  
  .card button {
    transition: background-color 0.3s ease-in-out;
  }
  