.product-card {
    cursor: pointer;
    border: 1px solid var(--main-color);
    padding: 1rem;
    background-color: white;
    border-radius: 0.75rem;
    transition: box-shadow 0.3s, transform 0.3s;
    position: relative;
    overflow: hidden;
  }
  
  .product-card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    transform: translateY(-0.5rem);
  }
  
  .product-image-container {
    background-color: var(--deep-gray);
    border-radius: 0.75rem;
    overflow: hidden;
    height: 13rem;
    position: relative;
  }
  
  .product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s;
  }
  
  .product-image:hover {
    transform: scale(1.1);
  }
  
  .flash-sale-badge {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    background-color: var(--flash-color);
    color: white;
    font-weight: bold;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    animation: pulse 2s infinite;
  }
  
  .product-image-alt {
    width: 100%;
    height: 10rem;
    object-fit: cover;
    transition: transform 0.5s;
  }
  
  .product-image-alt:hover {
    transform: scale(1.1);
  }
  
  .product-title {
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .product-price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }
  
  .product-price {
    font-size: 1.25rem;
    font-weight: 900;
    display: flex;
    align-items: center;
  }
  
  .rupee-icon {
    margin-right: 0.25rem;
  }
  
  .product-original-price {
    font-size: 1rem;
    color: var(--text-color);
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
  }
  
  .add-to-cart-button {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    color: white;
    border-radius: 0.5rem;
    transition: background-color 0.3s;
  }
  
  .add-to-cart-button:hover {
    background-color: var(--sub-main-color);
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .product-card {
      padding: 0.75rem;
    }
  
    .product-image-container, .product-image-alt {
      height: 10rem;
    }
  
    .product-price-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .product-price, .product-original-price {
      font-size: 1rem;
    }
  
    .add-to-cart-button {
      margin-top: 0.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .product-card {
      padding: 0.5rem;
    }
  
    .product-image-container, .product-image-alt {
      height: 8rem;
    }
  
    .product-price-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .product-price, .product-original-price {
      font-size: 0.875rem;
    }
  
    .add-to-cart-button {
      margin-top: 0.5rem;
    }
  }
  